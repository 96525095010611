<template>
  <v-item-group>
    <!-- start header pc -->
    <!-- แสดงด้านบน พื้นสีขาว -->
    <v-app-bar
      align="right"
      elevation="1"
      height="65px"
      class="hidden-xs-only"
      app
      color="#FFFFFF"
      >
      <v-row>
        <v-col cols="12" align="left" app class="pb-0 pt-2">
           <b>{{ shop_name }}</b>
        </v-col>
      </v-row>
      <v-spacer></v-spacer>
      <v-row>
        <v-col cols="12" align="right" app class="pb-0 pt-2" v-if="sub_scription_size">
          <!-- แพ็คเกจ : -->
          <span
            class="pl-1 text-success"
            style="cursor: pointer"
            v-if="sub_scription_size == 'pro' || sub_scription_size == 'Pro'"
          >{{use_language.package}} Pro 899 {{use_language.baht_month}}</span>
          <span
            class="pl-1 text-success"
            style="cursor: pointer"
            v-else-if="sub_scription_size == 'basic' || sub_scription_size == 'Basic'"
          >{{use_language.package}} Basic 399 {{use_language.baht_month}}</span>
        </v-col>
        <v-col cols="12" align="right" app class="pb-0 pt-2">
          {{ use_language.credit_balance}} :
          <span
            class="pl-1 text-danger"
            v-if="balance < 1"
            @click="get_data_shop(), toBalancePage()"
            style="cursor: pointer"
          >
            {{ " " + format_price(balance) }}</span
          >
          <span
            class="pl-1 text-success"
            v-else
            @click="get_data_shop(), toBalancePage()"
            style="cursor: pointer"
          >
            {{ " " + format_price(balance) }}</span
          ><span class="pl-1">{{use_language.bath}}</span>
        </v-col>
        <v-col cols="12" align="right" class="pb-2 pt-0" v-if="IsCODWallet">
          {{use_language.cod_balance}}:
          <span
            class="pl-1 text-danger"
            v-if="cod_balance < 1"
            @click="get_data_shop(), toCODWalletPage()"
            style="cursor: pointer"
          >
            {{ " " + format_price(cod_balance) }}</span
          >
          <span
            class="pl-1 text-success"
            v-else
            @click="get_data_shop(), toCODWalletPage()"
            style="cursor: pointer"
          >
            {{ " " + format_price(cod_balance) }}</span
          ><span class="pl-1">{{use_language.bath}}</span>
        </v-col>
      </v-row>

      <div>
         <label >{{set_language}}</label>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <v-icon color="#000000">mdi-translate</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="changeLangage('TH')">
              <v-list-item-title>{{
                this.use_language.thai_language
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="changeLangage('EN')">
              <v-list-item-title>{{
                this.use_language.englist_language
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="changeLangage('CH')">
              <v-list-item-title>{{
                this.use_language.china_language
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <div>
        <v-btn dark icon @click="bt_logout_click()">
          <v-icon color="#000000">mdi-logout</v-icon>
        </v-btn>
      </div>
    </v-app-bar>
    <!-- end header pc -->

    <!-- จอ PC เมนูรวม ย่อ ในส่วนนี้-->
    <!-- start new pc -->
    <v-navigation-drawer
      app
      :mini-variant.sync="mini"
      permanent
      color="#272727"
      class="hidden-xs-only"
      >
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-item v-if="mini == false">
            <img
              :src="branchLogo"
              style="width: 170px"
            />
           
          </v-item>

          <v-item v-else>
            <v-icon outlined fab color="#FFFFFF">mdi-menu</v-icon>
          </v-item>

          <v-list-item>
            <v-btn
              @click.stop="mini = !mini"
              outlined
              fab
              small
              bold
              color="#FFFFFF"
            >
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </v-list-item>

          <br />
        </v-list-item>
      </template>
      <v-divider></v-divider>

      <v-list dark shaped>
          <!-- หน้าแรก -->
          <v-list-item v-if="this.menuCode100 != 'null'"
            to="/dashboard"
            @click="reload_pc('/dashboard') "
            >
            <v-list-item-icon>
              <v-icon color="#FFFFFF">mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ use_language.home_page }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>


          <v-list-item v-if="ChatFeature"  to="/inbox"  @click="reload_pc('/inbox')" >
            <v-list-item-icon>
              <v-icon color="#FFFFFF">mdi-chat</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ use_language.chatmenu }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
 


          <!-- สต๊อก list-->
          <v-item v-if="this.menuCode200 != 'null'">
            <v-list-group prepend-icon="mdi-store" no-action color="none">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ use_language.stock }} </v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list>
                <v-list-item v-if="this.menuCode210 != 'null'"  @click="select_stock_menu({ title: 'warehouse', path: 'warehouse' })">
                  <v-list-item>
                    <li>{{ use_language['warehouse'] }}</li>
                  </v-list-item>
                </v-list-item>

                <v-list-item v-if="this.menuCode220 != 'null'"  @click="select_stock_menu({ title: 'my_product', path: 'my-product' })">
                  <v-list-item>
                    <li>{{ use_language['my_product'] }}</li>
                  </v-list-item>
                </v-list-item>

                <v-list-item v-if="this.menuCode230 != 'null'"  @click="select_stock_menu({ title: 'product_stock', path: 'my-stock' })">
                  <v-list-item>
                    <li>{{ use_language['product_stock'] }}</li>
                  </v-list-item>
                </v-list-item>

                <v-list-item v-if="this.menuCode240 != 'null'"  @click="select_stock_menu({ title: 'warehouse_transfer', path: 'transfer-list' })">
                  <v-list-item>
                    <li>{{ use_language['warehouse_transfer'] }}</li>
                  </v-list-item>
                </v-list-item>

                <v-list-item v-if="this.menuCode230 != 'null'"  @click="select_stock_menu({ title: 'report-stock', path: 'stock-report' })">
                  <v-list-item>
                    <li>{{ use_language['overviewstocking'] }}</li>
                  </v-list-item>
                </v-list-item>

              </v-list>
            </v-list-group>
          </v-item>

          <!-- ออเดอร์  list-->
          <v-item v-if="this.menuCode300 != 'null'" >
            <v-list-group prepend-icon="mdi-cart" no-action color="none">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ use_language.order }} </v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list>
                <v-list-item v-if="this.menuCode310 != 'null'"  @click="select_order_menu({ title: 'create_order', path: 'add-order' })">
                  <v-list-item>
                    <li>{{ use_language['create_order'] }}</li>
                  </v-list-item>
                </v-list-item>

                <v-list-item v-if="this.menuCode320 != 'null'"  @click="select_order_menu({ title: 'all_order', path: 'order' })">
                  <v-list-item>
                    <li>{{ use_language['all_order'] }}</li>
                  </v-list-item>
                </v-list-item>
                <v-list-item v-if="this.menuCode340 != 'null'"  @click="select_order_menu({ title: 'product_pack_list', path: 'product-pack-list' })">
                  <v-list-item>
                    <li>{{ use_language['parcel_delivery'] }}</li>
                  </v-list-item>
                </v-list-item>
                <v-list-item v-if="this.menuCode330 != 'null'"  @click="select_order_menu({ title: 'stored_documents', path: 'history-tax-invoice' })">
                  <v-list-item>
                    <li>{{ use_language['stored_documents'] }}</li>
                  </v-list-item>
                </v-list-item>

                <v-list-item v-if="this.menuCode340 != 'null'"  @click="select_order_menu({ title: 'import_orders', path: 'import-order-by-excel' })">
                  <v-list-item>
                    <li>{{ use_language['import_orders'] }}</li>
                  </v-list-item>
                </v-list-item>

             


              </v-list>
            </v-list-group>
          </v-item>

          <!-- จัดการผู้ใช้ -->
          <v-list-item v-if="this.menuCode400 != 'null'" to="/manageuser" @click="reload_pc('/manageuser')">
            <v-list-item-icon>
              <v-icon color="#FFFFFF">mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ use_language.user }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- รายงาน list -->
          <v-item v-if="this.menuCode500 != 'null'">
            <v-list-group prepend-icon="mdi-file-chart" no-action color="none">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ use_language.report }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list>
                <v-list-item v-if="this.menuCode510 != 'null'"  @click="select_report_menu({ title: 'report_cod', path: 'report' })">
                  <v-list-item>
                    <li>{{ use_language['report_cod'] }}</li>
                  </v-list-item>
                </v-list-item>

                <v-list-item v-if="this.menuCode520 != 'null'"  @click="select_report_menu({ title: 'report_sales', path: 'report-sales1' })">
                  <v-list-item>
                    <li>{{ use_language['report_sales'] }}</li>
                  </v-list-item>
                </v-list-item>

                <v-list-item v-if="this.menuCode530 != 'null'"  @click="select_report_menu({ title: 'report_best_seller', path: 'dashboard_best_sale_product' })">
                  <v-list-item>
                    <li>{{ use_language['report_best_seller'] }}</li>
                  </v-list-item>
                </v-list-item>

                <v-list-item v-if="this.menuCode540 != 'null'"  @click="select_report_menu({ title: 'report_area_utilization', path: 'report-space' })">
                  <v-list-item>
                    <li>{{ use_language['report_area_utilization'] }}</li>
                  </v-list-item>
                </v-list-item>
              </v-list>
            </v-list-group>
          </v-item>

          <!-- การเงิน list -->
          <v-item v-if="this.menuCode600 != 'null'">
            <v-list-group prepend-icon="mdi-wallet" no-action color="none">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{
                    use_language.finance
                  }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list>

                <v-list-item v-if="this.menuCode610 != 'null'"  @click="select_finance_menu({ title: 'payment_notification', path: 'notify_transfer' })">
                  <v-list-item>
                    <li>{{ use_language['payment_notification'] }}</li>
                  </v-list-item>
                </v-list-item>

                <v-list-item v-if="this.menuCode610 != 'null'"  @click="select_finance_menu({ title: 'withdraw_notification', path: 'notify_withdraw' })">
                  <v-list-item>
                    <li>{{ use_language['notification_withdrawal'] }}</li>
                  </v-list-item>
                </v-list-item>

                <v-list-item v-if="this.menuCode620 != 'null'"  @click="select_finance_menu({ title: 'balance_report', path: 'wallet' })">
                  <v-list-item>
                    <li>{{ use_language['balance_report'] }}</li>
                  </v-list-item>
                </v-list-item>

                <v-list-item v-if="this.menuCode630 != 'null'"  @click="select_finance_menu({ title: 'invoice_group', path: 'invoice-group' })">
                  <v-list-item>
                    <li>{{ use_language['invoice_group'] }}</li>
                  </v-list-item>
                </v-list-item>

                <v-list-item v-if="this.menuCode640 != 'null' && IsCODWallet == true"  @click="select_finance_menu({ title: 'cod_wallet', path: 'cod-wallet' })">
                  <v-list-item>
                    <li>{{ use_language['cod_wallet'] }}</li>
                  </v-list-item>
                </v-list-item>

              </v-list>
            </v-list-group>
          </v-item>

          <!-- ตั่งค่า -->
          <v-list-item v-if="this.menuCode700 != 'null'" to="/shop-setting" @click="reload_pc('/shop-setting')">
            <v-list-item-icon>
              <v-icon color="#FFFFFF">mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ use_language.general_settings }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <!-- เชื่อมต่อ -->
          <v-list-item v-if="this.menuCode800 != 'null'" to="/connect" @click="reload_pc('/connect')">
            <v-list-item-icon>
              <v-icon color="#FFFFFF">mdi-connection</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> {{ use_language.connect }} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- ลูกค้า -->
          <v-list-item v-if="this.menuCode900 != 'null'" to="/manage-customer" @click="reload_pc('/manage-customer')">
            <v-list-item-icon>
              <v-icon color="#FFFFFF">mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> {{ use_language.customer }} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

            <!-- ต่าขนส่ง -->
            <v-list-item v-if="this.menuCode1000 != 'null'"  to="/deliveryprice" @click="reload_pc('/deliveryprice')">
            <v-list-item-icon>
              <v-icon color="#FFFFFF">mdi-truck-fast</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> {{ use_language.freight }} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

            <!-- โปรโมชั่น -->
            <v-list-item v-if="this.menuCode1300 != 'null'"  to="/promotion" @click="reload_pc('/promotion')">
            <v-list-item-icon>
              <v-icon color="#FFFFFF">mdi-gift-open</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> {{use_language.promotion}} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- คู่มือการใช้งาน  halo ไม่อยากเห็น ปิดไว้ไม่ให้เห็น -->
          <v-list-item v-if="this.menuCode1200 != 'null' && branchId != 301"  to="/teach" @click="reload_pc('/teach')">
            <v-list-item-icon>
              <v-icon color="#FFFFFF">mdi-human-male-board</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> {{use_language.user_manual}} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

  
          <v-list-item v-if=" branchId == 2"  to="/package" @click="reload_pc('/package')">
            <v-list-item-icon>
              <v-icon color="#FFFFFF">mdi-cloud-lock-open-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> ต่ออายุแพ็กเกจ </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- ข้อเสนอแนะ -->
          <v-list-item v-if="this.menuCode1100 != 'null'" to="/feedback" @click="reload_pc('/feedback')">
            <v-list-item-icon>
              <v-icon color="#FFFFFF">fa-comment-dots</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> {{ use_language.feedback }} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
    </v-navigation-drawer>
    <!-- end new pc -->


    <!-- จอมือถือ ส่วนหัว -->
    <!-- start header mobile -->
    <v-app-bar
      align="right"
      elevation="0"
      height="20px"
      class="hidden-sm-and-up"
      dense
      fixed
      >
      <div class="hidden-sm-and-up">
        <v-app-bar color="#FFFFFF" elevation="1" absolute height="65px">
          <v-toolbar-items>
            <v-list>
              <v-list>
                <v-btn
                  @click.stop="drawer = !drawer"
                  outlined
                  fab
                  small
                  color="#000000"
                >
                  <v-icon>mdi-format-align-center</v-icon>
                </v-btn>
              </v-list>
            </v-list>
          </v-toolbar-items>
          <span>
            <v-row>
              <v-col cols="12" class="pa-0 ma-0 pl-4" align="left">
                  <b>{{ shop_name }}</b>
              </v-col>
              <v-col cols="12" class="pa-0 ma-0 pl-4" align="left">
                <div  v-if="header_title!=null" style="display: inline-flex;">
                  <span v-if="header_title.length > 0">{{ header_title[0].title }}</span>
                  <v-icon v-if="header_title.length > 1">mdi-chevron-right</v-icon>
                  <span v-if="header_title.length > 1" class="font-weight-regular body-1">{{ header_title[1].title }}</span>
                </div>
              </v-col>
            </v-row>
          </span>
        </v-app-bar>
      </div>
    </v-app-bar>
    <!-- end header mobile -->

    <!-- จอมือถือ ส่วนเมนู -->
    <!-- start new mobile -->
    <v-toolbar-items class="hidden-sm-and-up">
      <v-navigation-drawer app v-model="drawer" temporary color="#272727">
        <v-list>
          <v-list-item>
            <img
              v-if="branchLogo != null"
              :src="branchLogo"
              style="width: 170px"
            />
            <!-- <img v-else src="@/assets/oasys_logo1.png" style="width: 180px" /> -->
            <img v-else src="@/assets/W_logopackhai.png" style="width: 170px" />
            <v-list-item>
              <v-btn
                @click.stop="drawer = !drawer"
                outlined
                fab
                small
                color="#FFFFFF"
              >
                <v-icon>mdi-format-align-center</v-icon>
              </v-btn>
            </v-list-item>
          </v-list-item>
        </v-list>

        <v-list>
          <v-list-item>
            <v-list dark shaped>
              <!-- หน้าแรก -->
              <v-list-item v-if="this.menuCode100 != 'null'"  to="/dashboard" @click="reload('Dashboard', 'หน้าแรก', '/dashboard')">
                <v-list-item-icon>
                  <v-icon color="#FFFFFF">mdi-home</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{ use_language.home_page }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="ChatFeature"  to="/inbox" @click="reload('Dashboard', 'กล่องข้อควม', '/inbox')">
                <v-list-item-icon>
                  <v-icon color="#FFFFFF">mdi-chat</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{ use_language.chatmenu }}
                </v-list-item-title>
              </v-list-item>

              <!-- สินค้า no list -->
              <v-item v-if="this.menuCode200 != 'null'">
                <v-list-group prepend-icon="mdi-store" no-action color="none">
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ use_language.stock }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>

                  <v-list>
                    <v-list-item v-if="this.menuCode210 != 'null'" to="/warehouse" @click="reload('สต๊อก', 'คลังสินค้า', '/warehouse')">
                      <v-list-item><li>{{ use_language.warehouse }}</li></v-list-item>
                    </v-list-item>

                    <v-list-item v-if="this.menuCode220 != 'null'" to="/my-product" @click="reload('สต๊อก', 'สินค้าของฉัน', '/my-product')">
                      <v-list-item><li>{{ use_language.my_product }}</li></v-list-item>
                    </v-list-item>

                    <v-list-item v-if="this.menuCode230 != 'null'" to="/my-stock" @click="reload('สต๊อก', 'สต๊อกสินค้า', '/my-stock')">
                      <v-list-item><li>{{ use_language.product_stock }}</li></v-list-item>
                    </v-list-item>

                    <v-list-item v-if="this.menuCode240 != 'null'" to="/transfer-list" @click="reload('สต๊อก','นำเข้า/นำออก/โอนย้ายสินค้า','/transfer-list')">
                      <v-list-item><li>{{ use_language.warehouse_transfer_mobie1 }}/<br />{{ use_language.warehouse_transfer_mobie2 }}</li></v-list-item>
                    </v-list-item>

                    <v-list-item v-if="this.menuCode230 != 'null'" to="/stock-report" @click="reload('สต๊อก','ภาพรวมการเดินสต๊อก','/stock-report')">
                      <v-list-item><li>{{use_language.overviewstocking}}</li></v-list-item>
                    </v-list-item>
                  </v-list>
                </v-list-group>
              </v-item>

              <!-- ออเดอร์ no list -->
              <v-item v-if="this.menuCode300 != 'null'">
                <v-list-group prepend-icon="mdi-cart" no-action color="none">
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>{{ use_language.order }}</v-list-item-title>
                    </v-list-item-content>
                  </template>

                  <v-list>
                    <v-list-item v-if="this.menuCode310 != 'null'" to="/add-order" @click=" reload('ออเดอร์', 'สร้างออเดอร์ใหม่', '/add-order') " >
                      <v-list-item><li>{{ use_language.create_order }}</li></v-list-item>
                    </v-list-item>
                    <v-list-item v-if="this.menuCode320 != 'null'" to="/order" @click="reload('ออเดอร์', 'ออเดอร์ของฉัน', '/order')">
                      <v-list-item><li>{{ use_language.all_order }}</li></v-list-item>
                    </v-list-item>
                    <v-list-item v-if="this.menuCode330 != 'null'" to="/history-tax-invoice" accesskey="" @click="reload('Invoice', 'บิล', '/history-tax-invoice')">
                      <v-list-item><li> {{ use_language.stored_documents }} </li></v-list-item>
                    </v-list-item>
                    <v-list-item v-if="this.menuCode340 != 'null'"  @click="select_order_menu({ title: 'import_orders', path: 'import-order-by-excel' })">
                      <v-list-item>
                        <li>{{ use_language.import_orders }}</li>
                      </v-list-item>
                    </v-list-item>
                  </v-list>
                </v-list-group>
              </v-item>

              <!-- ผู้ใช้ -->
              <v-list-item v-if="this.menuCode400 != 'null'" to="/manageuser" @click="reload('ตั้งค่า', 'ผู้ใช้', '/manageuser')" >
                <v-list-item-icon>
                  <v-icon color="#FFFFFF">mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-title> {{ use_language.user }} </v-list-item-title>
              </v-list-item>

              <!-- รายงาน no list -->
              <v-item v-if="this.menuCode500 != 'null'">
                <v-list-group prepend-icon="mdi-file-chart" no-action color="none" >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        use_language.report
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </template>

                  <v-list>
                    <v-list-item
                      v-if="this.menuCode510 != 'null'" to="/report" @click="reload('รายงาน', 'รายงาน COD', '/report')" >
                      <v-list-item><li>{{ use_language.report_cod }}</li></v-list-item>
                    </v-list-item>

                    <v-list-item v-if="this.menuCode520 != 'null'" to="/report-sales" @click="reload('รายงาน', 'ยอดขาย', '/report-sales1')">
                      <v-list-item><li>{{ use_language.report_sales }}</li></v-list-item>
                    </v-list-item>

                    <v-list-item v-if="this.menuCode530 != 'null'" to="/dashboard_best_sale_product" @click="reload('รายงาน','สินค้าขายด','/dashboard_best_sale_product')">
                      <v-list-item><li>{{ use_language.report_best_seller }}</li> </v-list-item>
                    </v-list-item>

                    <v-list-item v-if="this.menuCode540 != 'null'" to="/report-sales" @click=" reload('รายงาน', 'การใช้พื้นที่', '/report-space')">
                      <v-list-item><li> {{ use_language.report_area_utilization }} </li></v-list-item>
                    </v-list-item>
                  </v-list>
                </v-list-group>
              </v-item>

              <!-- การเงิน no list  -->
              <v-item v-if="this.menuCode600 != 'null'">
                <v-list-group prepend-icon="mdi-wallet" no-action color="none">
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ use_language.finance }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>

                  <v-list>
                    <v-list-item v-if="this.menuCode610 != 'null'" to="/notify_transfer" @click=" reload('การเงิน', 'แจ้งชำระเงิน', '/notify_transfer') " >
                      <v-list-item ><li> {{ use_language.payment_notification }} </li></v-list-item >
                    </v-list-item>

                    <v-list-item v-if="this.menuCode610 != 'null'" to="/notify_withdraw" @click=" reload('การเงิน', 'แจ้งถอนเงิน', '/notify_withdraw') " >
                      <v-list-item ><li> {{ use_language.withdraw_notification }} </li></v-list-item >
                    </v-list-item>

                    <v-list-item v-if="this.menuCode620 != 'null'" to="/wallet" @click="reload('การเงิน', 'รายการ balance', '/wallet')" >
                      <v-list-item ><li>{{ use_language.balance_report }}</li></v-list-item >
                    </v-list-item>

                    <v-list-item v-if="this.menuCode630 != 'null'" to="/invoice-group" @click="reload('การเงิน', 'รายการใบแจ้งหนี้ที่จัดกลุ่ม', '/invoice-group')" >
                      <v-list-item ><li>{{ use_language.invoice_group }}</li></v-list-item >
                    </v-list-item>

                    <v-list-item v-if="this.menuCode640 != 'null' && IsCODWallet == true"  to="/cod-wallet" @click="reload('การเงิน', 'COD Wallet', '/cod-wallet')" >
                      <v-list-item ><li>{{ use_language.cod_wallet }}</li></v-list-item >
                    </v-list-item>
                  </v-list>
                </v-list-group>
              </v-item>

              <!-- ตั้งค่า -->
              <v-list-item v-if="this.menuCode700 != 'null'" to="/shop-setting" @click="reload('ตั้งค่า', 'ตั้งค่าทั่วไป', '/shop-setting')" >
                <v-list-item-icon>
                  <v-icon color="#FFFFFF">mdi-cog</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{ use_language.general_settings }}
                </v-list-item-title>
              </v-list-item>

              <!-- เชื่อมต่อ -->
              <v-list-item v-if="this.menuCode800 != 'null'" to="/connect" @click="reload('แพลตฟอร์ม', 'เชื่อมต่อ', '/connect')" >
                <v-list-item-icon>
                  <v-icon color="#FFFFFF">mdi-connection</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{ use_language.connect }}
                </v-list-item-title>
              </v-list-item>

              <!-- ลูกค้า -->
              <v-list-item v-if="this.menuCode900 != 'null'" to="/manage-customer" @click="reload('จัดการ', 'ลูกค้า', '/manage-customer')" >
                <v-list-item-icon>
                  <v-icon color="#FFFFFF">mdi-account-group</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{ use_language.customer }}
                </v-list-item-title>
              </v-list-item>

              <!-- ค่าขนส่ง -->
              <v-list-item v-if="this.menuCode1000 != 'null'" to="/deliveryprice" @click="reload('ค่าขนส่ง', '', '/deliveryprice')" >
                <v-list-item-icon>
                  <v-icon color="#FFFFFF">mdi-truck-fast</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{ use_language.freight }}
                </v-list-item-title>
              </v-list-item>

            <!-- โปรโมชั่น -->
            <v-list-item v-if="this.menuCode1300 != 'null'"  to="/promotion" @click="reload_pc('/promotion')">
            <v-list-item-icon>
              <v-icon color="#FFFFFF">mdi-gift-open</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> {{use_language.promotion}} </v-list-item-title>
            </v-list-item-content>
            </v-list-item>

                <!-- คู่มือการใช้งาน -->
                <v-list-item  v-if="this.menuCode1200 != 'null' && branchId != 301" to="/teach" @click="reload('คู่มือการใช้งาน', '', '/teach')" >
                <v-list-item-icon>
                  <v-icon color="#FFFFFF">mdi-human-male-board</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{use_language.user_manual}}
                </v-list-item-title>
              </v-list-item>

              <!-- ข้อเสนอแนะ -->
              <v-list-item v-if="this.menuCode1100 != 'null'" to="/feedback" @click="reload('ข้อเสนอแนะ', '', '/feedback')" >
                <v-list-item-icon>
                  <v-icon color="#FFFFFF">fa-comment-dots</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{ use_language.feedback }}
                </v-list-item-title>
              </v-list-item>

              <!-- แสดงยอด เงิน -->
              <v-list-item @click="reload('การเงิน', 'รายการ balance', '/wallet')" >
                <v-list-item-title @click="get_data_shop(), toBalancePage()">
                  {{ use_language.credit_balance}} :
                  <span class="pl-1 text-danger" v-if="balance < 1">{{
                    " " + format_price(balance)
                  }}</span>
                  <span class="pl-1 text-success" v-else>{{
                    " " + format_price(balance)
                  }}</span>
                  <span class="pl-1"> {{use_language.bath}}</span>
                </v-list-item-title>
              </v-list-item>

              <!-- แสดงยอดเงิน COD -->
              <v-list-item v-if="this.menuCode640 != 'null' && IsCODWallet == true"   @click="reload('การเงิน', 'COD Wallet', '/cod-wallet')" >
                <v-list-item-title @click="get_data_shop(), toCODWalletPage()">
                  {{use_language.cod_balance}} :
                  <span class="pl-1 text-danger" v-if="cod_balance < 1">{{
                    " " + format_price(cod_balance)
                  }}</span>
                  <span class="pl-1 text-success" v-else>{{
                    " " + format_price(cod_balance)
                  }}</span>
                  <span class="pl-1"> {{use_language.bath}}</span>
                </v-list-item-title>
              </v-list-item>

              <!-- ปุ่ม logount -->
              <v-list-item @click="bt_logout_click()">
                <v-list-item-title
                  ><v-icon>mdi-logout</v-icon>Logout</v-list-item-title
                >
              </v-list-item>

            </v-list>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-toolbar-items>
    <!-- end new mobile -->
  </v-item-group>
</template>

<!-- <v-btn v-if="this.shop_id == 117" dark text to="/mhao-chaina-order" @click="reload('/mhao-chaina-order')">รายการสั่งซื้อสินค้า </v-btn> -->

<script>
import axios from "axios"; 
import {
  shopService_dotnet,
  branchService_dotnet,
  generalService_dotnet,
  stockService_dotnet, 
} from "@/website/global";
import { format_number, format_price,get_languages,load_languages } from "@/website/global_function";
import { Toolbar_language } from "@/website/language/Toolbar_language";


export default {
  data: () => ({
    header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
    branchLogo: null,
    use_language: "null",
    set_language: null,
    mini: false,
    drawer: false,
    isConnected: false,
    personalID: "",
    branchId: null,
    url:null,
    shop:JSON.parse(localStorage.getItem("shop")),
    shop_feature:JSON.parse(localStorage.getItem("shop_feature")),
    authorization_token:null,
    // data shop
    balance: 0,
    cod_balance: 0,
    shop_name: null,
    sub_scription_size:null,
    IsCODWallet: false,
    header_title:null,
    menuCode100:null,
    menuCode200 :null,
    menuCode210 :null,
    menuCode220 :null,
    menuCode230 :null,
    menuCode240 :null,
    menuCode300 :null,
    menuCode310 :null,
    menuCode320 :null,
    menuCode330 :null,
    menuCode340 :null,
    menuCode400 :null,
    menuCode500 :null,
    menuCode510 :null,
    menuCode520 :null,
    menuCode530 :null,
    menuCode540 :null,
    menuCode600:null,
    menuCode610 :null,
    menuCode620 :null,
    menuCode630 :null,
    menuCode640 :null,
    menuCode700:null,
    menuCode800 :null,
    menuCode900 :null,
    menuCode1000 :null,
    menuCode1100:null,
    menuCode1200:null,
    menuCode1300 :null,
    ChatFeature:false,
  }),

  watch: {
  },

  computed: {

  },

  async created() { 
    await this.load_languages();
      //เก็บภาษามาไว้ใน use_language
     
      await this.get_languages_file();
      this.authorization_token =  localStorage.getItem('authorization_token');

      if(this.shop_feature!=null && this.shop_feature.filter((i)=>i.featureID==2).length>0){
        this.ChatFeature=true;
      }
      this.ChatFeature=true; 
      
    // get menu code menu ดูความหมายที่ตาราง ShopPermission
      this.menuCode100 = localStorage.getItem("100") //หน้าแรก
      this.menuCode200 = localStorage.getItem("200") //สต๊อก
      this.menuCode210 = localStorage.getItem("210") //คลังสินค้า
      this.menuCode220 = localStorage.getItem("220") //สินค้าของฉัน
      this.menuCode230 = localStorage.getItem("230") //สต๊อกสินค้า
      this.menuCode240 = localStorage.getItem("240") //นำเข้า/นำออก/โอนย้ายสินค้า
      this.menuCode300 = localStorage.getItem("300") //ออเดอร์
      this.menuCode310 = localStorage.getItem("310") //สร้างออเดอร์ใหม่
      this.menuCode320 = localStorage.getItem("320") //ออเดอร์ทั้งหมด
      this.menuCode330 = localStorage.getItem("330") //เอกสารที่จัดเก็บ
      this.menuCode340 = localStorage.getItem("340") //นำเข้าออเดอร์650
      this.menuCode400 = localStorage.getItem("400") //ผู้ใช้
      this.menuCode500 = localStorage.getItem("500") //รายงาน
      this.menuCode510 = localStorage.getItem("510") //รายงาน COD
      this.menuCode520 = localStorage.getItem("520") //รายงานยอดขาย
      this.menuCode530 = localStorage.getItem("530") //รายงานสินค้าขายดี
      this.menuCode540 = localStorage.getItem("540") //รายงานการใช้พื้นที่
      this.menuCode600 = localStorage.getItem("600") //การเงิน
      this.menuCode610 = localStorage.getItem("610") //แจ้งชำระเงิน - แจ้งถอนเงิน
      this.menuCode620 = localStorage.getItem("620") //รายงาน Balance
      this.menuCode630 = localStorage.getItem("630") //รายการใบแจ้งหนี้ที่จัดกลุ่ม
      this.menuCode640 = localStorage.getItem("640") //COD Wallet
      this.menuCode700 = localStorage.getItem("700") //ตั้งค่าทั่วไป
      this.menuCode800 = localStorage.getItem("800") //เชื่อมต่อ
      this.menuCode900 = localStorage.getItem("900") //ลูกค้า
      this.menuCode1000 = localStorage.getItem("1000") //ค่าขนส่ง
      this.menuCode1100 = localStorage.getItem("1100") //ข้อเสนอแนะ
      this.menuCode1200 = localStorage.getItem("1200") //คู่มือการใช้งาน
      this.menuCode1300 = localStorage.getItem("1300") //โปรโมชั่น
      

    
    var navigete = JSON.parse(localStorage.getItem("navigete"));
    if (navigete != null) {
      this.header_title = navigete;
    }
    this.shop_id = localStorage.getItem("shop_id");
     
     this.branchId= parseInt(localStorage.getItem("branch_id"));
   
    // if(branchid=="2"){
    //   this.branchLogo="https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/logo-oasys170x39-01.png";
    // } 
    this.url =  document.URL
    this.branchLogo=this.shop.logo_Header;
    
     
    // this.get_cod_balance()

    // this.get_shop_permisstion();
    this.get_data_shop();

    // check staff
    this.get_staffshop_only()

    this.IsCODWallet = false;
    if (
      localStorage.getItem("CODWallet") == null ||
      localStorage.getItem("CODWallet") == "no"
    ) {
      this.check_cod_wallet();
    } else {
      this.IsCODWallet = true;
    }

  await this.get_ViewType(); //ดึง viewtype แต่ละหน้า


  },

  methods: {
    load_languages,
    get_languages,
    format_number,
    format_price,
    
    async check_cod_wallet() {
      let res1 = await axios.post(
        shopService_dotnet + "Balance/get-cod-wallet-statement-list",
        {
          shopID: localStorage.getItem("shop_id"),
          dateFrom: null,
          dateTo: null,
        },
        {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+this.authorization_token} }
      ) 
      if (res1.data.statements.length > 0) {
        localStorage.setItem("CODWallet", "yes");
        this.IsCODWallet = true;
      } else {
        localStorage.setItem("CODWallet", "no");
        this.finance_menu = [
          { title: "payment_notification", path: "notify_transfer" },
          { title: "withdraw_notification", path: "notify_withdraw" },
          { title: "balance_report", path: "wallet" },
          { title: "invoice_group", path: "invoice-group" },
          // { title: 'COD Wallet', path: 'cod-wallet' },
        ];
      }
    },
    //facebook login 1
    async getUserData() {
      this.FB.api(
        "/me",
        "GET",
        { fields: "id,name,email,picture" },
        async (userInformation) => {
          if (userInformation.id) {
            this.personalID = userInformation.id;
            this.nameFace = userInformation.name;
            this.emailFace = userInformation.email;
            this.picFace = userInformation.picture["data"]["url"];
          } else {
            console.log("User cancelled login or did not fully authorize.");
          }
        }
      );
    },
    async sdkLoaded(payload) {
      this.isConnected = payload.isConnected;
      this.FB = payload.FB;
      if (this.isConnected) this.getUserData();
    },
    async onLogin() {
      this.isConnected = true;
      this.getUserData();
    },
    async onLogout() {
      this.isConnected = false;
      this.FB.api("/me", "delete", null, () => this.FB.logout());
    },
    //facebook login 1
    async bt_logout_click() {
      if ((await localStorage.getItem("login_value")) == 1) {
        await localStorage.clear();
        await this.$router.replace("/ss/login");
        await window.location.reload();
      } else if((localStorage.getItem("branch_id")) == 2){
        await localStorage.clear();
        await this.$router.replace("/loginOasys");
        await window.location.reload();
      }
      else {
        localStorage.clear();
        this.$router.replace("/login");
        window.location.reload();
      }
    },

    reload(title, subtitle, path) {
      this.get_data_shop();
      this.get_staffshop_only()
      localStorage.removeItem("OrderToken");
      if (path != this.$route.path) {
        this.$router.push(path);
      } else {
        window.location.reload();
      }
      this.header_title = [];
      this.header_title.push({
        title: title,
        path: path,
      });
      this.header_title.push({
        title: subtitle,
        path: path,
      });
      localStorage.setItem("navigete", JSON.stringify(this.header_title));
    },

   async reload_pc(path) {
      this.get_data_shop();
      this.get_staffshop_only()
      localStorage.removeItem("OrderToken");
      this.$router.push(path);
      // if (path != this.$route.path) {
      //   this.$router.push(path);
      // } else {
      //   window.location.reload();
      // }
      await this.get_ViewType();
    },

    select_stock_menu(item) {
      this.get_data_shop();
      this.get_staffshop_only()
      localStorage.removeItem("OrderToken");
      localStorage.setItem("navigete", JSON.stringify([item]));
      this.get_ViewType();
      var path = "/" + item["path"];
      if (path != this.$route.path) {
        this.$router.push("/" + item["path"]);
      } else {
        window.location.reload();
      }
    },

    select_order_menu(item) {
      this.get_data_shop();
      this.get_staffshop_only()
      localStorage.removeItem("OrderToken");
      localStorage.setItem("navigete", JSON.stringify([item]));
      this.get_ViewType();

      var path = "/" + item["path"];
      if (path != this.$route.path) {
        this.$router.push("/" + item["path"]);
      } else {
        if (path == "/add-order") {
          this.$router.push("/order");
          this.$router.push("/add-order");
          window.location.reload();
        } else {
          window.location.reload();
        }
      }
    },

    select_report_menu(item) {
      this.get_data_shop();
      this.get_staffshop_only()
      localStorage.removeItem("OrderToken");
      localStorage.setItem("navigete", JSON.stringify([item]));
      this.get_ViewType();

      var path = "/" + item["path"];
      if (path != this.$route.path) {
        this.$router.push("/" + item["path"]);
      } else {
        window.location.reload();
      }
    },

    select_finance_menu(item) {
      this.get_data_shop();
      this.get_staffshop_only()
      localStorage.removeItem("OrderToken");
      localStorage.setItem("navigete", JSON.stringify([item]));
      this.get_ViewType();
      var path = "/" + item["path"];
      if (path != this.$route.path) {
        this.$router.push("/" + item["path"]);
      } else {
        window.location.reload();
      }
    },

    select_setting_menu(item) {
      this.get_data_shop();
      this.get_staffshop_only()
      localStorage.removeItem("OrderToken");
      localStorage.setItem("navigete", JSON.stringify([item]));

      var path = "/" + item["path"];
      if (path != this.$route.path) {
        this.$router.push("/" + item["path"]);
      } else {
        window.location.reload();
      }
    },

    async get_data_shop() { 
      try {
        let response = await axios.post(
          shopService_dotnet + "Shop/get-shop",{
            'shopID': localStorage.getItem("shop_id"),
          },
          {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+this.authorization_token} }
        ) 
        if (response.status == 200) {
          this.balance = response.data.balance;
          
          this.cod_balance = response.data.codWallet;
          this.shop_name = response.data.name;
          this.sub_scription_size  = response.data.subScriptionSize
        } else {
          this.balance = 0;
          this.cod_balance = 0;
          this.shop_name = null;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.bt_logout_click();
        }  
      }
    },

    toBalancePage() {
      this.$router.push("/wallet");
    },

    toCODWalletPage() {
      this.$router.push("/cod-wallet");
    },

    changeLangage(index) {
      this.use_language = Toolbar_language[index];
      localStorage.setItem("set_language",index);
      window.location.reload();    
    },
    async get_languages_file(){
    
      this.use_language = await this.get_languages();
     
    },
    async get_ViewType () {
       await axios.post(stockService_dotnet+'Stock/view-type-shop', {
          "shopId": localStorage.getItem('shop_id'),
          "staffID": localStorage.getItem('staff_id'),
        },
        {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+this.authorization_token} }
      ).then((res) => {
          res.data.viewtype != null ?  localStorage.setItem('ViewType', res.data.viewtype) :  "";
         
        }).catch((error) => { });

      },


      async get_staffshop_only () {
         await axios.post(shopService_dotnet+'StaffShop/get-staffshop-only-by-staffid', {
            "id": localStorage.getItem('staff_id'),
          },{headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+this.authorization_token} }).then((res) => {
            if(res.data == null || res.data ==''){
              this.bt_logout_click()
            }
          }).catch((error) => { });

        },


  },
};
</script>

<style scoped>
>>> .btn {
  background-color: #f4511e;
  border: none;
  color: white;
  padding: 16px 32px;
  text-align: center;
  font-size: 16px;
  margin: 4px 2px;
  opacity: 1;
  transition: 0.3s;
}
>>> .btn:hover {
  opacity: 0.6;
}
>>> ::-webkit-scrollbar {
  width: 5px;
}
>>> ::-webkit-scrollbar-thumb {
  background: #272727;
  border-radius: 10px;
}
>>> ::-webkit-scrollbar-thumb:hover {
  background: rgba(124, 124, 124, 0.6);
}
</style>
